import React from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import Content from '../sections/about/FeaturesContent'
import { Section, SvgMode } from '../components/Core'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GetStarted from '../sections/landing1/GetStarted'
import Card1IconDark from '../assets/image/svg/pricingDark.inline.svg'
import Card1IconLight from '../assets/image/svg/pricingLight.inline.svg'
import Card2IconDark from '../assets/image/svg/settlementsDark.inline.svg'
import Card2IconLight from '../assets/image/svg/settlementsLight.inline.svg'
import Card3IconDark from '../assets/image/svg/portfolioDark.inline.svg'
import Card3IconLight from '../assets/image/svg/portfolioLight.inline.svg'
import Card4IconDark from '../assets/image/svg/taDark.inline.svg'
import Card4IconLight from '../assets/image/svg/taLight.inline.svg'
import FeaturesCard from '../sections/common/FeaturesCard'



const WhyUs = () => {
  const { t, ready } = useTranslation(['whyUs'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="whyUs" />
      <PageWrapper>
        <Hero bg={userDarkMode ? 'black' : 'bg'} title={t('title')}>
          {t('subTitle')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={0}>
          <Container>
            <Row>
              <FeaturesCard bgColor={userDarkMode ? 'bgDarkGray' : 'white'} linkUrl="pricing" title={t('competitive')}>
                <SvgMode
                  Dark={Card1IconDark}
                  Light={Card1IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeaturesCard>
              <FeaturesCard bgColor={userDarkMode ? 'bgDarkGray' : 'white'} linkUrl="platform" title={t('realTime')}>
                <SvgMode
                  Dark={Card2IconDark}
                  Light={Card2IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeaturesCard>
              <FeaturesCard bgColor={userDarkMode ? 'bgDarkGray' : 'white'} linkUrl="portfolio-management" title={t('portfolioMgt')}>
                <SvgMode
                  Dark={Card3IconDark}
                  Light={Card3IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeaturesCard>
              <FeaturesCard bgColor={userDarkMode ? 'bgDarkGray' : 'white'} linkUrl="platform" title={t('advancedTA')}>
                <SvgMode
                  Dark={Card4IconDark}
                  Light={Card4IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeaturesCard>
            </Row>
          </Container>
        </Section>
        <Content
          customBackground={userDarkMode ? 'black' : 'bg'}
          className="mt-5"
          bullets={true}
          bullets2={true}
          title={t('superior')}
          boldText={t('access')}
          text2={t('mostAdvanced')}
          bulletTitle={t('sophisticated')}
          bullet1={t('priceAlerts')}
          bullet2={t('newsFeed')}
          bullet4={t('multipleExchs')}
          bullet5={t('customTv')}
          bullet2Title={t('charting')}
          bullet6={t('chartTypes')}
          bullet7={t('timeframes')}
          bullet8={t('drawing')}
          bullet9={t('preferences')}
          bullet10={t('manageOrders')}
        />
        <Content
          customBackground={userDarkMode ? 'bgDarkGray' : 'white'}
          button={true}
          table={true}
          btnRegular={true}
          title={t('competitive')}
          boldText={t('makeTheMost')}
          text2={t('lowestCost')}
          buttonText={t('morePricing')}
          buttonUrl="pricing"
        />
        <Content
          customBackground={userDarkMode ? 'black' : 'bg'}
          title={t('realTime')}
          boldText={t('neverMiss')}
          text2={t('instantAccess')}
          linkText={t('moreBlockchain')}
          linkUrl="https://www.investopedia.com/terms/b/blockchain.asp"
        />
        <Content
          customBackground={userDarkMode ? 'bgDarkGray' : 'white'}
          button={true}
          title={t('portfolioMgt')}
          boldText={t('ruleAll')}
          text2={t('streamline')}
          buttonText={t('morePortfolio')}
          buttonUrl="portfolio-management"
        />
        <Content
          customBackground={userDarkMode ? 'black' : 'bg'}
          bullets={true}
          button={true}
          btnRegular={true}
          title={t('secure')}
          text1={t('perfectFlow')}
          bullet1={t('custodial')}
          bullet2={t('dataCenters')}
          bullet3={t('2fa')}
          bullet4={t('multiTiered')}
          buttonText={t('moreSecurity')}
          buttonUrl="security"
        />
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default WhyUs
